@import '~antd/dist/antd.css';

.App {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #0A3E03;
  height: 100vh;
  width: 100vw;
}

.button {
  background-color: #47AA4B;
  border-color: transparent;
}

.button:hover {
  background-color: #025034;
  border-color: transparent;
}

.create-button {
  width: 40vh;
  height: 7vh;
  font-size: large;
}

.input {
  width: 80vh;
  height: 10vh;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
}