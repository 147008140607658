@import '~antd/dist/antd.css';

.App-room {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #0A3E03;
  height: 100vh;
  width: 100vw;
}

.App-header {
  display: flex;
  flex: 1;
  height: 12vh;
  padding-left: 1%;
  padding-right: 1%;
  background-color: #0A3E03;
  width: 100vw;
}

.App-header-left {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
}

.App-header-right {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.image-button {
  height: 50px;
  width: 50px;
}

.icon-button {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.image-button:hover {
  opacity: 0.5;
}

.room-image {
  height: 70px;
  width: 70px;
  border-radius: 10px;
  object-fit: cover;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-image {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.selected-image {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
}

.song-message {
  display: flex;
  text-align: start;
  margin-top: 10px;
}

.resultName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.App-content {
  height: 68vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #0A3E03;
  color: white;
  padding-left: 2%;
  padding-right: 2%;
}
