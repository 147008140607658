@import '~antd/dist/antd.css';

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #0A3E03;
  height: 100vh;
  width: 100vw;
}

.button {
  background-color: #47AA4B;
  border-color: transparent;
}

.action-button {
  width: 20vh;
  height: 20vh;
  margin: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: large;
}

.button:hover {
  background-color: #025034;
  border-color: transparent;
}
