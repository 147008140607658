@import "~antd/dist/antd.css";

.App-request {
  border-radius: 10px;
  padding: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.resultBlock {
  width: 25%;
  height: 25vh;
}

.resultBlock:hover {
  opacity: 0.5;
}

.resultImage {
  width: 15vh;
  height: 15vh;
  object-fit: cover;
  border-radius: 10px;
}
