@import "~antd/dist/antd.css";

.now-playing-image {
  height: 25vh;
  width: 25vh;
  border-radius: 10px;
  object-fit: cover;
}

.tunedin-image {
  height: 8vh;
  width: 8vh;
  border-radius: 8vh;
  object-fit: cover;
  margin-right: 10px;
}

.App-now-playing {
  height: 50vh;
  border-radius: 10px;
  padding: 30px;
  font-size: calc(10px + 2vmin);
  background-color: #47AA4B;
  color: white;
  display: flex;
  flex-direction: column;
}
