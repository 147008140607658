@import '~antd/dist/antd.css';

.App-home {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #0A3E03;
  padding-left: 20%;
  padding-right: 20%;
  height: 100vh;
  width: 100vw;
}

.button {
  background-color: #47AA4B;
  border-color: transparent;
}

.home-action-button {
  width: 16vh;
  height: 16vh;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: large;
}

.button:hover {
  background-color: #025034;
  border-color: transparent;
}